/**
 * Date constants and utility functions.
 *
 * @author WooCommerce
 * @since  1.0.0
 */

/**
 * External dependencies
 */
import moment from 'moment';
import 'moment-timezone';
import { sprintf, __, _n } from '@wordpress/i18n';

/**
 * Options for what range of dates to display on event list.
 *
 * @since 1.0.0
 */
export const DATE_RANGES = {
	TODAY: 'today',
	THIS_WEEK: 'this-week',
	THIS_MONTH: 'this-month',
	CUSTOM: 'custom',
};

/**
 * Convert directions
 *
 * @since 1.0.0
 */
export const CONVERT_DIRECTIONS = {
	LOCAL_TO_SERVER: 'LOCAL_TO_SERVER',
	SERVER_TO_LOCAL: 'SERVER_TO_LOCAL',
};

/**
 * Date range labels.
 *
 * @since 1.0.0
 */
export const DATE_RANGE_TYPE_LABELS = {
	TODAY: __( 'Today', 'woocommerce-bookings-availability' ),
	THIS_WEEK: __( 'This week', 'woocommerce-bookings-availability' ),
	THIS_MONTH: __( 'This month', 'woocommerce-bookings-availability' ),
};

/**
 * Option labels for each date range type.
 *
 * @since 1.0.0
 */
export const DATE_RANGE_OPTIONS = [
	{ value: DATE_RANGES.TODAY, label: DATE_RANGE_TYPE_LABELS.TODAY },
	{ value: DATE_RANGES.THIS_WEEK, label: DATE_RANGE_TYPE_LABELS.THIS_WEEK },
	{ value: DATE_RANGES.THIS_MONTH, label: DATE_RANGE_TYPE_LABELS.THIS_MONTH },
];

/**
 * Option labels for each date range type for calendar.
 *
 * @since 1.0.0
 */
export const DATE_RANGE_OPTIONS_CAL = [
	{ value: DATE_RANGES.THIS_WEEK, label: DATE_RANGE_TYPE_LABELS.THIS_WEEK },
	{ value: DATE_RANGES.THIS_MONTH, label: DATE_RANGE_TYPE_LABELS.THIS_MONTH },
];

/**
 * String format for date query.
 */
export const DATE_QUERY_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';

/**
 * Find the date min and max for a given date range type.
 *
 * Calculates the date min/max relative to the current day when necessary.
 * For week range, will start from the startOfWeek day and calculate onwards.
 *
 * @since 1.0.0
 *
 * @param {string} dateRangeType    String value for a date range (from DATE_RANGE array).
 * @param {int}    [intervalOffset] Optional offset for the date range.
 * @param {int}    currentDate 		Value to denote if there is a current date to use.
 *
 * @return {Object} minDate and maxDate Date objects
 */
export function getDateLimits( dateRangeType, intervalOffset = 0, currentDate = 1 ) {
	let minDate = moment().startOf( 'day' ).toDate();
	let maxDate = moment().startOf( 'day' ).toDate();
	let intervalDays = 1;

	if ( DATE_RANGES.THIS_MONTH === dateRangeType ) {
		minDate = moment( minDate ).add( intervalOffset, 'months' ).toDate();
		minDate.setDate( currentDate );
		maxDate = moment( minDate ).endOf( 'month' ).toDate();
	}

	let minDisplayDate = minDate;

	if ( DATE_RANGES.THIS_WEEK === dateRangeType ) {
		intervalDays = 7;
		const startOfWeek = parseInt( wc_bookings_availability_args.start_of_week, 10 );
		const _minDate = moment().day( ( intervalDays * intervalOffset ) + startOfWeek ).startOf( 'day' );
		if ( minDate < _minDate ) {
			minDate = _minDate.toDate();
		}
		maxDate = moment( minDate ).endOf( 'week' ).toDate();
		minDisplayDate = moment( minDate ).startOf( 'week' ).toDate();
	}

	if ( DATE_RANGES.TODAY === dateRangeType ) {
		const startDayOffset = intervalDays * intervalOffset;
		const endDayOffset = ( intervalDays * ( intervalOffset + 1 ) ) - 1;
		minDate.setDate( minDate.getDate() + startDayOffset );
		maxDate.setDate( maxDate.getDate() + endDayOffset );
	}

	return {
		minDisplayDate,
		minDate,
		maxDate,
	};
}

/**
 * Add time if the date is today.
 *
 * The date can then be used to provide more detailed availability when calling the API.
 *
 * @since import moment from 'moment';
 *
 * @param {Date}  minDate Date.
 *
 * @return {Date} minDate with current time if it is today.
 */
export function addTimeIfToday( minDate ) {
	if ( minDate && moment( minDate ).isSame( moment(), 'day' ) ) {
		return moment().format( DATE_QUERY_FORMAT );
	}

	return minDate;
}

/**
 * Check if date is a custom type or not.
 *
 * @since 1.0.0
 *
 * @param {string} dateRangeType String value for a date range (from DATE_RANGE array).
 *
 * @return {boolean} True if using a custom date range.
 */
export function isCustom( dateRangeType ) {
	return DATE_RANGES.CUSTOM === dateRangeType;
}

/**
 * Get date range as a human readable string.
 *
 * @since 1.0.0
 *
 * @param {string} dateRangeType    String value for a date range (from DATE_RANGE array).
 * @param {int}    [intervalOffset] Optional offset for the date range.
 *
 * @return {String} Human readable string representing the date range.
 */
export function getReadableDateRangeString( dateRangeType, intervalOffset = 0 ) {
	const { minDisplayDate, minDate, maxDate } = getDateLimits( dateRangeType, intervalOffset );

	if ( DATE_RANGES.THIS_MONTH === dateRangeType ) {
		return moment( minDate ).format( 'MMMM YYYY' );
	}

	if ( minDate.getTime() === maxDate.getTime() ) {
		return moment( minDate ).format( 'MMM DD, YYYY' );
	}

	return moment( minDisplayDate ).format( 'MMM DD - ' ) + moment( maxDate ).format( 'MMM DD, YYYY' );
}

/**
 * Get date range type as header navigation title.
 *
 * @since 1.0.0
 *
 * @param {string} dateRangeType String value for a date range (from DATE_RANGE array).
 *
 * @return {String} Human readable string representing the date range type.
 */
export function getDateRangeTypeString( dateRangeType ) {
	switch ( dateRangeType ) {
		case DATE_RANGES.TODAY:
			return DATE_RANGE_TYPE_LABELS.TODAY;
		case DATE_RANGES.THIS_MONTH:
			return DATE_RANGE_TYPE_LABELS.THIS_MONTH;
		case DATE_RANGES.THIS_WEEK:
		default:
			return DATE_RANGE_TYPE_LABELS.THIS_WEEK;
	}
}

/**
 * Get date range as a min/max in 'YYYY-MM-DD' format.
 *
 * @since 1.0.0
 *
 * @param {string} dateRangeType    String value for a date range (from DATE_RANGE array).
 * @param {int}    [intervalOffset] Optional offset for the date range.
 * @param {int}    [currentDate]    Optional current date.
 *
 * @return {Object} minDate and maxDate in 'YYYY-MM-DD' format.
 */
export function getDateQueryLimits( dateRangeType, intervalOffset = 0, currentDate = 1 ) {
	const { minDate, maxDate } = getDateLimits( dateRangeType, intervalOffset, currentDate );

	return formatDateQueryLimits( minDate, maxDate );
}

/**
 * Format dates according to date query format.
 *
 * @param {object} minDate          Min date
 * @param {object} maxDate          Max date
 *
 * @return {Object} minDate and maxDate in DATE_QUERY_FORMAT format.
 */
export function formatDateQueryLimits( minDate, maxDate ) {
	return {
		minDate: moment( minDate ).format( DATE_QUERY_FORMAT ),
		maxDate: moment( maxDate ).endOf( 'day' ).format( DATE_QUERY_FORMAT ),
	};
}

/**
 *  Gets the day of the week.
 *
 * @since 1.0.0
 *
 * @param {int} day The selected start of week.
 *
 * @return {string} The processed day.
 */
export function getDayOfWeek( day ) {
	return moment().day( day ).format( 'ddd' );
}

/**
 *  Gets the calendar month view start time.
 *
 * @since 1.0.0
 * @param {string} dateRangeType  String value for a date range (from DATE_RANGE array).
 * @param {int}    intervalOffset Optional offset for the date range.
 * @return {object} { minDate, maxDate }.
 */
export function getCalStartEndTime( dateRangeType, intervalOffset ) {
	switch ( dateRangeType ) {
		case DATE_RANGES.THIS_WEEK:
			return calendarWeekStartEndTime( intervalOffset );

		case DATE_RANGES.THIS_MONTH:
		default:
			return calendarMonthStartEndTime( intervalOffset );
	}
}

function calendarWeekStartEndTime( intervalOffset ) {
	const today = moment( new Date() ).add( intervalOffset * 7, 'day' );
	const todayDay = today.isoWeekday();

	const wpStartOfWeek = parseInt( wc_bookings_availability_args.start_of_week, 10 );
	// Change WP format to ISO. ISO 1..7, 1=Monday vs. WordPress 0..7, 0=Sunday.
	const startOfWeek = wpStartOfWeek === 0 ? 7 : wpStartOfWeek;

	let weekStart = null;
	if ( todayDay > startOfWeek ) {
		weekStart = moment( today ).subtract( todayDay - startOfWeek, 'day' );
	} else if ( todayDay < startOfWeek ) {
		weekStart = moment( today ).subtract( 7 - ( startOfWeek - todayDay ), 'day' );
	} else {
		weekStart = today;
	}

	const weekEnd = moment( weekStart ).add( 6, 'day' );

	return {
		minDate: weekStart.startOf( 'day' ),
		maxDate: weekEnd.endOf( 'day' ),
	};
}

function calendarMonthStartEndTime( intervalOffset ) {
	const date = moment( new Date() ).add( intervalOffset, 'month' );

	const wpStartOfWeek = parseInt( wc_bookings_availability_args.start_of_week, 10 );
	// Change WP format to ISO. ISO 1..7, 1=Monday vs. WordPress 0..7, 0=Sunday.
	const startOfWeek = wpStartOfWeek === 0 ? 7 : wpStartOfWeek;
	const monthStart = date.startOf( 'month' );
	const monthStartOfWeekDay = monthStart.isoWeekday();
	/*
	 * Calculate column where the month start will be placed.
	 * This calculates true modulo ( never negative ).
	 */
	const startColumn = ( 7 + ( ( monthStartOfWeekDay - startOfWeek ) % 7 ) ) % 7;
	/*
	 * Calculate start time: how many days from the previous month we need to include,
	 * in order to have calendar without empty days in the first row.
	 */
	const startTime = moment( monthStart ).subtract( startColumn, 'day' );

	const monthEnd = date.endOf( 'month' );
	const monthEndOfWeekDay = monthEnd.isoWeekday();
	const endColumn = ( 7 + ( ( monthEndOfWeekDay - startOfWeek ) % 7 ) ) % 7;
	/*
	 * Calculate end date: how many days from the next month we need to include.
	 * We want to have calendar without empty days in the last row.
	 */
	const endTime = moment( monthEnd ).add( 6 - endColumn, 'day' );

	return {
		minDate: startTime.startOf( 'day' ),
		maxDate: endTime.endOf( 'day' ),
		currentMonth: date,
	};
}

/*
 * Get date as humanreadable string.
 *
 * @since 1.0.0
 *
 * @param {string} date    String value for a date.
 *
 * @return {string} date in 'dddd, MMMM DD' format.
 */
export function getHumanReadableDate( date ) {
	const dateFormat = 'dddd, MMMM DD';

	return moment( date ).format( dateFormat );
}

/**
 * Formats a time duration.
 *
 * @since 1.0.0
 *
 * @param {object}    product Product information.
 * @param {timestamp} date    Date the duration starts from.
 *
 * @return {string} The formatted duration.
 */
export function formatDuration( product, date ) {
	const { duration, durationUnit } = product;
	const startDate = moment( date );
	const startDateDay = startDate.format( 'Do' );
	const startDateMonth = startDate.format( 'MMMM' );
	const timeFormat = wc_bookings_availability_args.time_format_moment;
	let endDate;

	let formattedDuration, durationPeriodSeparator;
	switch ( durationUnit ) {
		case 'minute':
			endDate = moment( date ).add( duration, durationUnit );
			formattedDuration = `${ startDate.format( timeFormat ) }-${ endDate.format( timeFormat ) } (${ getDisplayTimezone( date ) })`;
			break;
		case 'hour':
			endDate = moment( date ).add( duration, durationUnit );
			durationPeriodSeparator = __( ' to ', 'woocommerce-bookings-availability' );
			formattedDuration = `${ startDate.format( timeFormat ) } ${ durationPeriodSeparator } ${ endDate.format( timeFormat ) } (${ getDisplayTimezone( date ) })`;
			break;
		// 'night' is just a synonym of the 'day' duration for accommodation bookings.
		case 'night':
		case 'day':
			endDate = moment( date ).add( duration - 1, durationUnit );
			let endDateDay = endDate.format( 'Do' );
			let endDateMonth = endDate.format( 'MMMM' );

			// Assume it's of fixed duration
			let days = sprintf( _n( `%d ${ durationUnit }`, `%d ${ durationUnit }s`, duration * product.min_duration, 'woocommerce-bookings-availability' ), duration * product.min_duration );
			if ( 'customer' === product.duration_type && product.min_duration !== product.max_duration ) {
				days = sprintf( __( `%d-%d ${ durationUnit }s`, 'woocommerce-bookings-availability' ), duration * product.min_duration, duration * product.max_duration );
				formattedDuration = `${ days }`;
				break;
			}

			if ( startDateMonth !== endDateMonth ) {
				endDateMonth = `${ endDateMonth } `;
			} else {
				endDateMonth = '';
			}

			durationPeriodSeparator = '';
			switch ( duration ) {
				case 1:
					durationPeriodSeparator = endDateMonth = endDateDay = '';
					break;
				case 2:
					durationPeriodSeparator = __( ' and ', 'woocommerce-bookings-availability' );
					break;
				default:
					durationPeriodSeparator = ' - ';
			}

			formattedDuration = `${ days }, ${ startDateMonth } ${ startDateDay } ${ durationPeriodSeparator } ${ endDateMonth }${ endDateDay } `;
			break;

		case 'month':
			endDate = moment( date ).add( duration, durationUnit );
			/* translators: %d number of month. */
			const months = sprintf( _n( '%d month', '%d months', duration, 'woocommerce-bookings-availability' ), duration );
			const startOfDuration = startDate.startOf( 'month' );
			const endOfDuration = endDate.endOf( 'month' );
			if ( 1 === duration ) {
				formattedDuration = `${ months }, ${ startOfDuration.format( 'MMMM D' ) } - ${ endOfDuration.format( 'D' ) }`;
			} else {
				formattedDuration = `${ months }, ${ startOfDuration.format( 'MMMM D' ) } - ${ endOfDuration.format( 'MMMM D' ) }`;
			}
	}

	return formattedDuration;
}

/**
 * Return friendly string indicating no slots exist for range type.
 *
 * @since 1.0.0
 *
 * @param {string} dateRangeType String value for a date range (from DATE_RANGE array).
 *
 * @return {string} String indicating no slots are available in the range.
 */
export function getNoSlotsInRangeMessage( dateRangeType ) {
	switch ( dateRangeType ) {
		case DATE_RANGES.THIS_WEEK:
			return __( 'No available times this week.', 'woocommerce-bookings-availability' );
		case DATE_RANGES.THIS_MONTH:
			return __( 'No available times this month.', 'woocommerce-bookings-availability' );
		case DATE_RANGES.TODAY:
		default:
			return __( 'No available times this day.', 'woocommerce-bookings-availability' );
	}
}

/**
 * Return friendly string indicating an error occurred for range type.
 *
 * @since 1.0.0
 *
 * @param {string} dateRangeType String value for a date range (from DATE_RANGE array).
 *
 * @return {string} String indicating an error occurred loading slots in the range.
 */
export function getErrorLoadingSlotsInRangeMessage( dateRangeType ) {
	switch ( dateRangeType ) {
		case DATE_RANGES.THIS_WEEK:
			return __( 'We weren\'t able to load times for this week.', 'woocommerce-bookings-availability' );
		case DATE_RANGES.THIS_MONTH:
			return __( 'We weren\'t able to load times for this month.', 'woocommerce-bookings-availability' );
		case DATE_RANGES.TODAY:
		default:
			return __( 'We weren\'t able to load times for this day.', 'woocommerce-bookings-availability' );
	}
}

/**
 * Convert client/server dates.
 *
 * @param {date}    date Date to convert.
 * @param {string}  convertDirection Direction of conversion (from CONVERT_DIRECTIONS array).
 *
 * @return {object} The converted date object.
 */
export function convertDate( date, convertDirection ) {
	const offsetHours = getClientServerTimezoneOffsetHours( date );
	const localizedDate = moment.tz( date, DATE_QUERY_FORMAT, wc_bookings_availability_args.server_timezone );

	switch ( convertDirection ) {
		case CONVERT_DIRECTIONS.SERVER_TO_LOCAL:
			localizedDate.add( offsetHours, 'hours' );
			break;
		case CONVERT_DIRECTIONS.LOCAL_TO_SERVER:
			localizedDate.subtract( offsetHours, 'hours' );
			break;
		default:
			throw 'Invalid convertDirection option.';
	}

	return localizedDate;
}

/**
 * Localize client/server date based on Bookings timezone settings.
 *
 * @param {date}    date Date to convert.
 * @param {string}  convertDirection Direction of conversion (from CONVERT_DIRECTIONS array).
 *
 * @return {string} The converted date in 'YYYY-MM_DDTHH:mm:ss' format.
 */
export function localizeDate( date, convertDirection ) {
	if ( ! wc_bookings_availability_args.timezone_conversion ) {
		return date;
	}

	const convertedDate = convertDate( date, convertDirection );
	return convertedDate.format( DATE_QUERY_FORMAT );
}

/**
 * Returns the hour offset between the client and the server based on Bookings timezone settings.
 *
 * @param {string} referenceDate date string in DATE_QUERY_FORMAT to use as time to calculate the timezone difference at.
 *
 * @return {number} Number of hours between server and client.
 */
function getClientServerTimezoneOffsetHours( referenceDate ) {
	if ( ! wc_bookings_availability_args.timezone_conversion ) {
		return 0;
	}

	const referenceTime = moment( referenceDate, DATE_QUERY_FORMAT );
	const clientOffset = referenceTime.utcOffset();
	referenceTime.tz( wc_bookings_availability_args.server_timezone );
	const serverOffset = referenceTime.utcOffset();

	return ( clientOffset - serverOffset ) / 60.0;
}

/**
 * Returns the current timezone abbreviation based on Bookings
 * settings and the client timezone.
 *
 * @param {string} referenceDate date string in DATE_QUERY_FORMAT to use as time to calculate the timezone at.
 *
 * @return {string} Timezone abbreviation string.
 */
export function getDisplayTimezone( referenceDate ) {
	if ( ! wc_bookings_availability_args.timezone_conversion ) {
		// Use server's timezone.
		return moment.tz( referenceDate, DATE_QUERY_FORMAT, wc_bookings_availability_args.server_timezone ).format( 'z' );
	}
	// Guess client's timezone.
	return moment.tz( referenceDate, DATE_QUERY_FORMAT, moment.tz.guess() ).format( 'z' );
}

/**
 * Checks if a specific date is restricted.
 *
 * @since 1.1.7
 *
 * @param {timestamp} date    Date the duration starts from.
 * @param {object}    product Product information.
 *
 * @return {boolean} Is restricted or not.
 */
export function getIsRestrictedDay( date, product ) {
	if ( ! product.has_restricted_days || ! product.restricted_days ) {
		return false;
	}

	// Days included in the list are available as start days.
	const day_of_week = moment( date ).day();
	if ( undefined === product.restricted_days[ day_of_week ] ) {
		return true;
	}

	return false;
}
